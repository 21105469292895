const zones = document.querySelectorAll('.js_upload_zone');

zones.forEach(zone => {
    var id = `#${zone.id}`;
    const url = zone.dataset.url;
    const maxfiles = parseInt(zone.dataset.maxfiles);
    const type = zone.dataset.type;
    const grid = zone.dataset.grid;

    var previewNode = $(id + " .dropzone-item");

    previewNode.id = "";
    var previewTemplate = previewNode.parent(".dropzone-items").html();
    previewNode.remove();

    var myDropzone = new Dropzone(id, {
        url: url,
        parallelUploads: maxfiles,
        maxFilesize: 15,
        previewTemplate: previewTemplate,
        previewsContainer: id + " .dropzone-items",
        clickable: id + " .dropzone-select",
        success: function(file, response) {
            if (response.module !== undefined && response.module === 'statistics') {
                toastr.success(trans(response.message));

                return;
            }

            renderDocument(response, type, grid);

            if (response.readyForApproval !== undefined && response.readyForApproval === true) {
                const btnRequestApproval = $('.js-request-approval');
                btnRequestApproval.removeClass('disabled');
            }
        },
        error: function(file, response) {
            if (response.module !== undefined && response.module === 'statistics') {
                if (response.errors !== undefined) {
                    $('.js-excel-upload-list').html('');

                    $('.js-excel-upload-container')
                        .addClass('d-flex')
                        .removeClass('d-none');

                    $('.js-excel-upload-text').text(response.error);

                    response.errors.forEach(error => {
                        $('.js-excel-upload-list').append(`<li>${error}</li>`)
                    })

                    return;
                }

                toastr.error(trans(response.error));

                return;
            }

            if(response.error === "This file type is not allowed.") {
                if (type == 'image') {
                    toastr.warning(trans("tooltips.media_images"));

                    return;
                }

                toastr.warning(trans("tooltips.media_accommodation"));

                return;
            }

            if(response.error === "This file is too small.") {
                toastr.warning(trans("tooltips.media_images"));
            }
        }
    });


    myDropzone.on("addedfile", function(file) {
        $(document).find( id + " .dropzone-item").css("display", "");
    });

    myDropzone.on("totaluploadprogress", function(progress) {
        $( id + " .progress-bar").css("width", progress + "%");
    });

    myDropzone.on("sending", function(file) {
        $('.js-excel-upload-container')
            .removeClass('d-flex')
            .addClass('d-none');

        $( id + " .progress-bar").css("opacity", "1");
    });

    myDropzone.on("complete", function(progress) {
        var thisProgressBar = id + " .dz-complete";

        setTimeout(function(){
            $( thisProgressBar + " .progress-bar, " + thisProgressBar + " .progress").css("opacity", "0");
        }, 300)

        if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
            this.removeAllFiles(true);
        }
    });
})

renderDocument = (response, type, grid) => {
    if (type !== 'file' && type !== 'image') {
        return;
    }

    document.querySelector(`#${grid}`).innerHTML = '';
    if(type === 'file') {
        response.files.forEach(file => {
            document.querySelector(`#${grid}`).innerHTML += `
            <div class="asset file">
                <i class="file-type bi bi-file-earmark-pdf fs-3x"></i>
                ${file.originalFilename}
                <div class="file-options">
                    <a class="download-mark" href="${file.file_url}" target="_blank">
                        <i class="bi bi-arrow-bar-down"></i>
                    </a>
                    <a class="btn-remove js_remove-asset" data-url="${file.delete_url}">
                        <i class="bi bi-trash "></i>
                    </a>
                </div>
            </div>
            `
        })
    }

    if(type === 'image') {
        response.files.forEach(file => {
            document.querySelector(`#${grid}`).innerHTML += `
            <div class="asset file-image draggable" id="${ file.id }" data-remove-url="${file.delete_url}" data-sorting-url="">
                <div class="image-container ">
                    <img src="${file.resized_url}" alt="" />
                </div>
            
                <div class="cover-tag">${trans('common.cover_image')}</div>
                <button class="btn btn-handle js_handle"><i class="bi bi-arrows-move"></i></button>
                <button class="btn btn-remove-upload" onclick="window.removeUploadedFile(this, '${file.delete_url}')"><i class="bi bi-trash"></i></button>
            </div>
            `
        })
    }

    initRemoveHandlers();
}

initRemoveHandlers = () => {
    $(".js_remove-asset").click(function(e){
        e.preventDefault();

        let actionUrl = e.target.dataset.url;
        if(!actionUrl) {
            if (e.target.closest('.js_remove-asset').dataset.url){
                actionUrl = e.target.closest('.js_remove-asset').dataset.url
            } else {
                return;
            }
        }

        fetch(actionUrl, {
            method: "GET",
        })
            .then(function(response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(function (response) {
                e.target.closest(".asset").remove()
            })
            .catch(function(error) {
                console.log(error);
                toastr.error(trans('generic.error'))
            });
    });
}

initRemoveHandlers();


