const fileImageEls = document.querySelectorAll('.js-file-image');

fileImageEls.forEach(element => {
    element.addEventListener('click', function(e) {
        const allFileImageEls = document.querySelectorAll('.js-file-image');
        allFileImageEls.forEach(fileElement => {
            fileElement.querySelector('.cover-tag').style.display = 'none';
        })

        element.classList.toggle('selected');

        const selectedFileImageEls = document.querySelectorAll('.js-file-image.selected');
        let firstSelected = selectedFileImageEls.item(0);

        if (null !== firstSelected) {
            let firstSelectedCoverTag = firstSelected.querySelector('.cover-tag')

            firstSelectedCoverTag.style.display = 'flex';
        }

        let action = element.dataset.action;
        let position = Array.prototype.indexOf.call(selectedFileImageEls, element);

        action = action.replace('__REPLACE__', position);

        fetch(action, {method: "GET"})
            .then(function(response) {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                return response;
            })
            .catch(function(error) {
                console.log(error);
            });

        location.reload();
    });
});